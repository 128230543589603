<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";
import Stat from "@/components/widgets/stat";
import VueMonthlyPicker from "vue-monthly-picker";
import moment from "moment";

export default {
  locales: {
    en: {
      months: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
    pt: {
      months: [
        "Jan",
        "Fev",
        "Mar",
        "Abr",
        "Mai",
        "Jun",
        "Jul",
        "Ago",
        "Set",
        "Out",
        "Nov",
        "Dez",
      ],

      Username: "Usuário",
      Name: "Nome",
      Date: "Data",
      Value: "Valor",
      Level: "Nível",
      Bonus: "Bônus",

      "We apologize, we are unable to retrieve information at this time. Please try again later.":
        "Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.",
      "No records found.": "Nenhum registro encontrado.",
    },
    es: {
      months: [
        "Jan",
        "Fev",
        "Mar",
        "Abr",
        "Mai",
        "Jun",
        "Jul",
        "Ago",
        "Set",
        "Out",
        "Nov",
        "Dez",
      ],

      Username: "Nombre de Usuario",
      Name: "Nombre",
      Date: "Data",
      Value: "Valor",
      Level: "Nivel",
      Bonus: "Bonus",

      "We apologize, we are unable to retrieve information at this time. Please try again later.":
        "Nos disculpamos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.",
      "No records found.": "No se encontraron registros.",
    },
  },
  components: {
    Layout,
    Stat,
    VueMonthlyPicker
  },
  data() {
    return {
      calendar: "",
      monthly: {
        placeHolder: moment().format("MMM/YYYY"),
        labels: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
      },
      date: moment().format("YYYY-MM"),

      table: {
        heade: [
          "Data",
          "L. Esquerdo",
          "L. Direito",
          "%",
          "Pagamento",
          "CashBack",
          "Bônus",
        ],
        body: null,
      },

      statData: null,
    };
  },
  methods: {
    showDate(date) {
      this.date = date;
    },
    getList() {
      this.table.body = null;

      api
        .get("reports/equiparacao?date=" + this.date)
        .then((response) => {
          if (response.data.status == "success") {
            this.table.body = response.data.list;

            this.statData = [
              {
                type: "line",
                icon: "bx-dollar",
                title: "Total de Bônus",
                value: this.$options.filters.currency(response.data.total),
              },
            ];
          }
        })
        .catch(() => {
          this.table.body = []
        })
    },
  },
  mounted() {
    this.getList();
  },
  watch: {
    calendar: function (v) {
      this.date = moment(v._d).format("YYYY-MM");
      this.getList();
    },
  },
};
</script>

<template>
  <Layout>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <router-link tag="a" to="/">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
            <path d="M5 22h14a2 2 0 0 0 2-2v-9a1 1 0 0 0-.29-.71l-8-8a1 1 0 0 0-1.41 0l-8 8A1 1 0 0 0 3 11v9a2 2 0 0 0 2 2zm5-2v-5h4v5zm-5-8.59 7-7 7 7V20h-3v-5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v5H5z">
            </path>
          </svg>
        </router-link>
      </li>
      <li class="breadcrumb-item">Bonificações</li>
      <li class="breadcrumb-item">Equiparação</li>
    </ol>

    <div class="page-title-box d-md-flex justify-content-between">
      <div>
        <h3>{{ t('Equiparação') }}</h3>
        <p></p>
      </div>
      <vue-monthly-picker v-model="calendar" dateFormat="MMM/YYYY" :placeHolder="monthly.placeHolder" :monthLabels="monthly.labels" :clearOption="false" alignment="center"></vue-monthly-picker>
    </div>

    <div class="row">
      <div v-for="stat of statData" :key="stat.icon" class="col-md-3">
        <Stat :type="stat.type" :icon="stat.icon" :title="stat.title" :value="stat.value" />
      </div>
    </div>

    <div v-if="!table.body" class="card">
      <div class="card-body min-vh-25 d-flex justify-content-center align-items-center">
        <b-spinner variant="default" role="status"></b-spinner>
      </div>
    </div>

    <div v-else-if="table.body && table.body.length == 0" class="card">
      <div class="card-body min-vh-25 d-flex justify-content-center align-items-center">
        <div class="text-center">
          <i class="far fa-sad-cry font-size-24"></i><br>
          nenhum registro encontrado
        </div>
      </div>
    </div>

    <div v-else class="card">
      <div class="card-body">
        <div class="table-responsive">
          <table class="table table-nowrap">
            <thead class="thead-light">
              <tr>
                <th v-for="th in table.heade" :key="th">{{ t(th) }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(td, index) in table.body" :key="index">
                <td v-for="(data, row) in td" :key="row" class="notranslate">
                  <div v-if="row === 'side_left'">
                    {{ data | points }}
                  </div>
                  <div v-else-if="row === 'side_right'">
                    {{ data | points }}
                  </div>
                  <div v-else-if="row === 'plan_limit'">
                    {{ data | currency }}
                  </div>
                  <div v-else-if="row === 'payment'">
                    {{ data | currency }}
                  </div>
                  <div v-else-if="row === 'cashback'">
                    {{ data | currency }}
                  </div>
                  <div v-else-if="row === 'bonus'">
                    {{ data | currency }}
                  </div>
                  <div v-else>
                    {{ data }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </Layout>
</template>